import type { SupportedLocales } from '~/types'

const keywordTranslated: Record<SupportedLocales, Record<string, string>> = {
  fr: {
    'motorhome': 'motorise',
    'class-a': 'classe-a',
    'class-b': 'classe-b',
    'class-c': 'classe-c',
    'campervan': 'campervan',
    'trailer': 'roulotte',
    'fifth-wheel': 'sellette',
    'travel-trailer': 'caravane-de-voyage',
    'toy-hauler': 'roulotte-cargo',
    'micro-trailer': 'mini-roulotte',
  },
  en: {
    'motorise': 'motorhome',
    'classe-a': 'class-a',
    'classe-b': 'class-b',
    'classe-c': 'class-c',
    'campervan': 'campervan',
    'roulotte': 'trailer',
    'sellette': 'fifth-wheel',
    'caravane-de-voyage': 'travel-trailer',
    'roulotte-cargo': 'toy-hauler',
    'mini-roulotte': 'micro-trailer',
  },
}

function getTranslatedKeyword(keyword: string, locale: string) {
  return keywordTranslated?.[locale as SupportedLocales]?.[keyword]
}

export default defineNuxtRouteMiddleware((to) => {
  const { $i18n } = useNuxtApp()

  const localePath = useLocalePath()
  const getRouteBaseName = useRouteBaseName()

  const keyword = Array.isArray(to.params?.keyword) ? to.params?.keyword.join(' ') : to.params?.keyword

  if (keyword) {
    /**
     * If the keyword is translated, redirect to the translated keyword page
     */
    const translatedKeyword = getTranslatedKeyword(keyword, $i18n.locale.value)
    if (translatedKeyword && translatedKeyword !== keyword) {
      return navigateTo(localePath({ name: getRouteBaseName(to), params: { keyword: translatedKeyword, destinationPath: to.params?.destinationPath, campgroundSlug: to.params?.campgroundSlug } }), {
        redirectCode: 302,
      })
    }
  }
})
